import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { MailIcon, PhoneIcon, LocationMarkerIcon } from '@heroicons/react/solid'
// import { PhoneIcon } from '@heroicons/react/solid'
// import { MailIcon } from '@heroicons/react/solid'

const Footer = () => (
    <footer className='bg-dark w-full'>
        <div className="px-3 md:px-6 mx-auto max-w-7xl xl:px-12">

            <div className="box-border flex flex-wrap pt-12 pb-0 text-base leading-tight text-gray-500 md:pb-12">
                <div className="flex-initial pb-12 leading-tight">
                    <div className="box-border text-base text-gray-500">
                        <StaticImage
                            src="../images/logo.png"
                            width={150}
                            // quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt="Field to Fork Logo"
                        />
                        {/* <div>
                            <MailIcon />
                        </div> */}
                        {/* <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-lg mb-7 bg-gradient-to-br from-purple-500 via-indigo-600 to-blue-500 rounded-xxl">
                            
                        </div> */}
                        <ul className="p-0 ml-3 text-base leading-tight list-none">
                            <li className="box-border flex py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <PhoneIcon width={20} />
                                <a href="tel:07877312604" className="ml-3 text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">07877 312 604</a>
                            </li>
                            <li className="box-border flex py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <MailIcon width={20} heihgt={20} />
                                <a href="mailto:danny@fieldtoforkproduce.co.uk" className="ml-3 text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">danny @fieldtoforkproduce.co.uk</a>
                            </li>
                            <li className="flex box-border pt-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <LocationMarkerIcon width={20} />
                                <p className="ml-3 text-base leading-tight no-underline bg-transparent cursor-pointer ">890 Garrett Lane</p>
                            </li>
                            <li className="box-border text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <p className="ml-8 text-base leading-tight no-underline bg-transparent cursor-pointer ">London</p>
                            </li>
                            <li className="box-border text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <p className="ml-8 text-base leading-tight no-underline bg-transparent cursor-pointer ">SW7 0AD</p>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <div width="6,3" className="flex-initial w-1/2 px-2 pb-12 leading-tight md:w-1/4">
                    <div className="box-border text-base text-gray-500">
                        <h2 className="mb-8 font-sans text-xl font-bold tracking-wide text-gray-100 md:text-2xl">
                            Home
                        </h2> */}
                {/* <ul className="p-0 m-0 text-base leading-tight list-none">
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Drag'n Drop Builder</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Developer Inspector</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Theme Configuration</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Template Customization</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Developer API</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">System Import</a>
                            </li>
                        </ul> */}
                {/* </div>
                </div> */}
                {/* <div width="6,3" className="flex-initial w-1/2 px-2 pb-12 leading-tight md:w-1/4">
                    <div className="box-border text-base text-gray-500">
                        <h2 className="mb-8 font-sans text-xl font-bold tracking-wide text-gray-100 md:text-2xl">
                            Company
                        </h2> */}
                {/* <ul className="p-0 m-0 text-base leading-tight list-none">
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Contact us</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Blog</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Culture</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Jobs</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Technology</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Security</a>
                            </li>
                        </ul> */}
                {/* </div>
                </div> */}
                {/* <div className="flex-initial w-1/2 px-2 pb-12 leading-tight md:w-1/4">
                    <div className="box-border text-base text-gray-500">
                        <h2 className="mb-8 font-sans text-xl font-bold tracking-wide text-gray-100 md:text-2xl">
                            Support
                        </h2> */}
                {/* <ul className="p-0 m-0 text-base leading-tight list-none">
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Getting started</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Suggest a feature</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Help center</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Server status</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Report a bug</a>
                            </li>
                            <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                                <a href="#_" className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400">Press Kit</a>
                            </li>
                        </ul> */}
                {/* </div>
                </div>*/}
            </div>

            <div className="flex flex-col items-center justify-center md:justify-between w-full py-10 text-xs leading-none text-gray-500 border-t border-gray-600 md:flex-row">
                <p className="block m-0 text-sm font-semibold text-gray-500">
                    © {new Date().getFullYear()}, Field To Fork Produce Ltd. All rights reserved.
                </p>
                <div className="flex flex-grow-0 flex-shrink-0 leading-none ">
                    <span className="inline-flex justify-center w-full mt-6 space-x-5 sm:ml-auto md:mt-0">
                        <a href="#" className="text-gray-400 hover:text-gray-200">
                            <span className="sr-only">Facebook</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"></path>
                            </svg>
                        </a>

                        <a href="#" className="text-gray-400 hover:text-gray-200">
                            <span className="sr-only">Instagram</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd"></path>
                            </svg>
                        </a>

                        <a href="#" className="text-gray-400 hover:text-gray-200">
                            <span className="sr-only">Twitter</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                            </svg>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
